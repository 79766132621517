#app {
  height: 100%;
}

.login-card-header {
  margin-bottom: 15px;
}

.custom-control-input {
  z-index: 1 !important;
}

.is-disabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
