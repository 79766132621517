/* Styles from MIS site.css */

/* <Html, body, footer> */

html {
  position: relative;
  min-height: 100%;
}

.page-footer {
  background-color: #fff;
  padding: 15px;
  z-index: 10 !important;
  box-shadow: 0 0 2px 2px #dedede;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
  font-family: 'Fira Sans' !important;
  overflow-y: scroll;
}

footer {
  font-size: 1.2rem !important;
  z-index: 1000 !important;
}

/* </ Html, body, footer> */

main {
  background-color: #fdfdfd !important;
}

/* <Toasts> */

#toasts {
  z-index: 1050;
  position: absolute;
  top: 50px;
  right: 10px;
}

.toast {
  width: 275px;
}

/* </Toasts> */

/* <Auth page> */

.auth-page {
  background: url("./images/login-min.jpg") !important;
  background-repeat: no-repeat;
  background-size: auto;
}

.auth-card {
  background-color: rgba(255, 255, 255, 0.8) !important;
  padding-right: 5rem;
  padding-left: 5rem;
}

@media (min-width: 576px) {
  .auth-block {
      width: 90% !important;
  }
}

@media (min-width: 768px)
{
  .auth-block {
      width: 75% !important;
  }
}

@media (min-width: 992px)
{
  .auth-block {
      width: 50% !important;
  }
}

@media (min-width: 1200px)
{
  .auth-block {
      width: 40% !important;
  }
}

/* </ Auth page> */

/* <Layout> */

.global-loader-spinner {
  margin: 0.375rem 1rem;
}

.global-loading-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  opacity: 0.5;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -ms-filter: blur(8px);
  -o-filter: blur(8px);
  filter: blur(8px);
}

.user-menu {
  top: auto !important;
  left: auto !important;
}

.account-name {
  max-width: 30rem !important;
  width: fit-content !important;
}

#app.has-compact-menu .app-aside {
  width: 4rem !important;
}

#app.has-compact-menu .app-aside + .app-main {
  padding-left: 4rem;
}

.bg-white-2 {
  background-color: #fefefe !important;
}

/* </ Layout> */

/* <ETC> */

.no-display {
  display: none;
}

.page-header {
  color: #888C9B;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.list-group-item {
  border-width: 1px 0 0 !important;
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in Internet Explorer 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

/* </ETC> */
